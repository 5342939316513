body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.MuiSelect-select {
  padding-right: 0 !important;
}

.cm-readonly * .cm-activeLine {
  background: transparent !important;
}

.cm-readonly * .cm-activeLineGutter {
  background: transparent !important;
}

.cm-readonly.header * .cm-gutters {
  padding-left: 7px !important;
}

.cm-readonly.header.bigger * .cm-gutters {
  padding-left: 16px !important;
}

.cm-readonly.footer.bigger * .cm-gutters {
  padding-left: 9px !important;
}